
    .printDetailClass body {
        color: #000000 !important;
        opacity: unset !important;
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }

    .printDetailClass .nav-bar, .printDetailClass nav, .printDetailClass .newinvoice-btn-wrapper, .printDetailClass .no-print {
        display: none;
    }

    .printDetailClass .content {
        margin-top: 0px !important;
    }

    .printDetailClass .content-body > .mdc-layout-grid {
        padding-top: 0px !important;
    }

    .printDetailClass .content .mini-drawer {
        display: none;
    }

    .printDetailClass .total-section-wrapper .mdc-layout-grid {
        padding: 0px !important;
    }

    .printDetailClass .first-header-bar {
        margin-top: 10px !important;
        border-bottom: none !important;
    }

    .printDetailClass .header-bar {
       /* margin-top: 10px !important;*/
        padding-top: 0px !important;
        border-bottom: none !important;
    }

    .printDetailClass .card-body {
        width: 100%;
        border: none;
        margin-top: 0px;
        box-shadow: none !important;
        min-height: 1160px !important;
    }
    .printDetailClass .voucher-print .card-body {
        min-height: unset !important;
    }

    .printDetailClass .print-active {
        display: block;

    }

    .printDetailClass table.print-active tfoot {
        display: table-footer-group;
        width: 100% !important;

    }

    .printDetailClass table.print-active tfoot tr, table.print-active tfoot tr {
        width: 100%;
        border-bottom: none;
    }

    .printDetailClass table.print-active th, table.print-active td {
        line-height: unset;
        background: unset;
        border-bottom: none;
        padding-left: 0px;

    }

    .printDetailClass table.header > thead th:first-child {
        width: 54%;
        padding: 0px;
    }

    .printDetailClass table.footer > tfoot td {
        width: 40%;
        color: #000000 !important;
        opacity: 0.8 !important;
        padding-right: 0 !important;
    }

    .printDetailClass .fixed-table-height {
        box-shadow: none !important;
        min-height: 900px !important;
        font-size: 12px !important;
    }

    .printDetailClass table.data-table {
        table-layout: fixed !important;
        min-width: 98% !important;
    }

    .printDetailClass table.data-table tbody tr {
        width: 100%;
        border-bottom: none !important;
    }

    .printDetailClass table.data-table tbody td {
        color: #000000 !important;
        opacity: unset !important;
        line-height: 24px !important;

    }

    .printDetailClass table.data-table thead th {
        color: #000000 !important;
        opacity: unset !important;
        font-weight: 500 !important;
        line-height: 28px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    }

    .printDetailClass table.data-table tbody tr td:nth-child(2) {
        width: 300px !important;
    }

    .printDetailClass .total-footer {
        min-height: 645px;
    }

    .printDetailClass .hr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    }

    .printDetailClass .extra-content {
        content: '';
        display: block;
        margin-right: 100px;
    }

    .printDetailClass table.header-table {
        top: 0;
        display: none;
    }

    .printDetailClass table.header-table tr td {
        content: '';
        display: none;
    }

    .printDetailClass table.footer-table {
        position: fixed;
        bottom: 0;
        page-break-after: auto;
    }

    .printDetailClass table.footer-table tr td {
        text-align: center;
        color: #000;
        line-height: 60px;
        font-size: 12px;
        content: '----';
    }


    .printDetailClass table {
        page-break-after: auto;
    }

    .printDetailClass tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    .printDetailClass td {
        page-break-inside: avoid;
        page-break-after: auto
    }

    .printDetailClass thead {
        display: table-header-group !important;
    }

    .printDetailClass tfoot {
        display: table-footer-group
    }

    .printDetailClass .footer-block {
        page-break-after: always;
    }
    .printDetailClass .print-body:last-child .footer-block{
        page-break-after: avoid !important;
    }

    .printDetailClass .footer-block span {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        color: #000000 !important;
        opacity: unset !important;
    }

    .printDetailClass .print-body {
        position: relative;
        padding-bottom: 25px;
    }


    .printDetailClass .tfoot-td {
        line-height: 23px !important;
    }

    .printDetailClass .line-height-none {
        line-height: 0 !important;
    }

    .printDetailClass .signature-line {
        width: 18%;
        height: 10px;
        margin: 26px 0px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    }

    .printDetailClass .header-middle-content {
        border-bottom: none !important;
    }

    .printDetailClass .fixed-table-wrapper {
        box-shadow: none !important;
        width: auto !important;
    }

    .printDetailClass table.pdf-table {
        font-size: 12px;
    }

    .printDetailClass  table.pdf-table td, th {
        padding: 0 16px;
        white-space: normal;
    }

    .printDetailClass table.pdf-table th {
        line-height: unset;
        font-size: 12px;
        font-weight: normal;
        width: 100px !important;
        white-space: normal;
        padding: 10px 12px;
    }

    .printDetailClass table.pdf-table td {
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 11px;
        padding: 10px 12px;
    }

    .printDetailClass table.pdf-table tr {
        border-bottom: 1px solid #f7f7f7;
    }

    .printDetailClass td:first-of-type, th:first-of-type {
        padding-left: 24px;
    }

    .printDetailClass td:last-of-type, th:last-of-type {
        padding-right: 24px;
    }
    .printDetailClass .voucher-print tfoot td:first-of-type {
        padding-left: unset !important;
    }
    .printDetailClass .voucher-print tfoot td:last-of-type {
        padding-right:  unset !important;
    }

    .printDetailClass .pdf-table-wrapper {
        height: 970px !important;
    }

    .printDetailClass .zoom-reduce {
        zoom: 0.7;
    }

    .printDetailClass .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
        max-width: unset !important;
        height: 25px !important;
    }

    .printDetailClass .first-header-bar .mdc-layout-grid {
        padding: 10px 24px 0px !important;
    }

    .printDetailClass .header-middle-content {
        padding: 5px 0px 8px !important;
    }

    .printDetailClass .three-input-wrapper .mdc-layout-grid {
        padding: 10px 24px 8px !important;
    }
    .printDetailClass .custom-three-input-wrapper .mdc-layout-grid {
        padding:15px 24px 0px !important;
    }
    .printDetailClass .total-section-wrapper .mdc-layout-grid {
        padding: 0px 24px 0px !important;
    }
    .printDetailClass .total-section-wrapper .mdc-layout-grid:first-child{
        padding:0px 24px 0px!important;
    }
    .printDetailClass table.total-section-wrapper tr td.total-content-data {
        line-height: 24px !important;
    }

    .printDetailClass .page-number {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.6);
    }

    .printDetailClass .left-footer .mdc-layout-grid {
        padding-left: 0px !important;
    }
    .printDetailClass .header-border-bottom {
        border-bottom: 1px solid #c5c5c5 !important;
        padding: 0px 0px 8px !important;
    }
    .printDetailClass .rosia-srn-card {
        min-height: 1120px !important;
    }
    @media print {
        .printDetailClass table.total-section-wrapper tr td.total-content-data {
            line-height: 3px !important;
        }
    }


