:root {
  --max-table-height: 400px;
}

body {
  margin: 0;
}
.rosia-table {
}
.rosia-table td {
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
}

.rosia-table th {
  white-space: nowrap;
}

.rosia-table thead {

}

/* .card-header-bar {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #fff;
  line-height: 64px;
}

.card-header-bar input::-webkit-input-placeholder {
  color: white;
}

.card-header-bar input::-moz-placeholder {
  color: white;
}

.card-header-bar input:-ms-input-placeholder {
  color: white;
}

.card-header-bar input:-moz-placeholder { 
  color: white;
} */

.header-menu {
  /*display: flex;*/
  margin-left: auto;
}

.app-header {
  background: var(--primary-color);
  top: 0;
  color: #fff;
  width: 100%;
  position: fixed;
  z-index: 400;
  padding: 0 24px;
  line-height: 64px;
  vertical-align: middle;
}

.app-header h1 {
  display: inline-block;
  color: #fff;
}

.app-header > span > i {
  vertical-align: inherit !important;
}

.cursor-pointer {
  cursor: pointer;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}
.center-align {
  text-align: center;
}

.grid-header {
  text-align: left;
  cursor: pointer;
}

.grid-header-no-cursor {
  text-align: left;
}

.grid-num-header {
  text-align: right;
  cursor: pointer;
}


.app-container {
  padding: 24px;
  margin-top: 60px;
}

.pagination-wrapper {
  background: #fbfbfb;
  line-height: 48px;
  padding: 0px 10px;
}

.filter-wrapper {
  margin-top: 88px;
  /*overflow-y: scroll;*/
}
.filter-head {
  padding: 16px 24px;
  border-bottom: 1px solid #eeeeee;
}
.filter-body {
  padding: 16px 24px;
}

.flex-display {
  display: flex;
}

.filter-arrow-icon {
  margin: auto 8px auto auto;
}

.filter-title {
  cursor: pointer;
  display: flex;
}

.login-wrapper {
}

.invalid-message {
  color: #ef5350;
  font-size: 12px;
}

/*.linear-progress-wrapper {
    padding: 10px 0px;
}*/

.active-link {
  border-left: 2px solid #FF4081;
}

.nav-right-item {
  float: right;
  margin-right: 24px;
}

.temp-progress-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  z-index: 1000;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* .create-fab-button {
  position: fixed;
  bottom: 85px;
  right: 56px;
} */

.Select-control {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px !important;
  border-collapse: collapse;
  box-shadow: none !important;
}

.grid-padding {
  padding: 12px 0px 16px;
}

/* extra css UI */
/* .card-header-bar .mdc-temporary-drawer__drawer{
  line-height: 0;
}

.mdc-temporary-drawer__drawer .filter-wrapper {
    margin-top:24px;
}
.mdc-temporary-drawer__drawer{
    margin-top:64px;
}
.mdc-temporary-drawer__drawer  .default-horizontal-padding-24 {
    line-height:21px;
} */

