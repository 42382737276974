@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

/* @import url('https://fonts.googleapis.com/css?family=Helvetica:300'); */


/*
Variables
 */

:root {
    --primary-color: #37474f;
    --accent-color: #2196f3;
    --accent-hover-color: #2196f3;
    --accent-light-color: #2196f3;
    --dark-ripple-color: #2196f3;
    --light-ripple-color: #2196f3;
    --primary-accent-focused-color: #2196f3;
    --secondary-color: #000;
    --gray-color: #9F9F9F;
    --gray-color-2: #EEEEEE;
    --tooltip-gray: #616161;
    --active-opacity: 0.87;
    --hover-opacity: 0.54;
    --inactive-opacity: 0.38;
    --disabled-opacity: 0.26;
    --line-divider-color: #eeeeee;
    --form-invalid: #ef5350;
    --form-required: #1294f7;
    --background-1: #F1F1F1;
    --background-2: #FAFAFA;
    --success: #5AB963;
    --error: #F95B45;
    --border-bottom: #E9E9E9;
    --left-drawer-color: #f3faff;
    --main-background: #fbfdff;
    --regular: 400;
    --medium-bold: 600;
    --medium: 500;
    --pivot-table-border-color: #ededed;
    --pivot-table-bg-color: #fafafa;
    /*Over riding Material Primary color*/
    --mdc-theme-primary: #2196f3!important;
    --mdc-ripple-surface-width: 56px;
    --mdc-ripple-surface-height: 56px;
    --mdc-ripple-fg-size: 33.6px;
    --mdc-ripple-fg-scale: 2.65464;
    --mdc-ripple-fg-translate-start: 8.21562px, 1.2px;
    --mdc-ripple-fg-translate-end: 11.2px, 11.2px;
}

.portrait,
.portrait-type,
.landscape,
.visibility-hidden {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.print_display_only,
.display_none {
    display: none;
}

.display_flex {
    display: flex;
    flex-wrap: wrap;
}

.display_print_only {
    display: none;
}

.disabled-opacityFull {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;
}


/**
To hide the scrollbars
 */

.mdc-permanent-drawer::-webkit-scrollbar {
    display: none;
}


/* ::-webkit-scrollbar {
    display: none;
} */


/*
Main body
 */

body {
    background: var(--main-background);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: var(--regular);
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}


/**************************************************************************************************************************************************************/


/*
Login
 */


/* .login-wrapper {
    display: table;
    background: url('./svg/cloud.svg') repeat-x bottom;
    position: absolute;
    height: 100vh;
    width:100%;
    text-align: center;
}
.login-center-div {
    display: table-cell;
    vertical-align: middle;
    padding: 24px 0;
}
.login-logo {
    margin: 0 auto;
}
.login-logo img {
    height: 63px;
    width: 76px;
}
.login-title {
    margin: 127px auto 0;
}
.login-title h2 {
    font-weight: var(--regular);
}
.login-body {
    margin: 48px auto 127px;
    width: 30%;
}
.login-btn {
    background-color: var(--accent-color);
}
.input-wrapper {
    position: relative;
}
.input-wrapper input {
    line-height: 49px;
    margin: 0 auto ;
    width: 90%;
    font-size: 16px;
    padding: 30px 5% 0 5%;
    border: none;
    border-bottom: 1px solid var(--line-divider-color);
    outline:none;
}
.input-wrapper > label  {
    color:#999;
    font-size:16px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5%;
    top:36%;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}
.input-wrapper > input:focus ~ label {
    top: 35%;
    font-size: 12px;
    transform: translateY(-35%);
    color: var(--accent-color)!important;
}
.input-wrapper > input:invalid ~ label{
    color: var(--form-invalid);
}
.focused-label {
    top: 35%!important;
    font-size: 12px!important;
    transform: translateY(-35%)!important;
    color: var(--accent-color)!important;
}
.login-form button {
    margin-top: 4px;
    width: 100%;
    line-height: 56px;
    height: 56px;
    font-size: 14px;
    font-weight:var(--medium);
    border-radius: 0;
    color: #ffffff;
}
.login-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.login-footer {
    color: #a0a0a0;
    font-weight: var(--medium);
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: #ffffff !important;
} */


/**************************************************************************************************************************************************************/


/*
Headers
*/

h1 {
    font-size: 24px;
    /*line-height: 1.33;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
    text-transform: uppercase;
}

h2 {
    font-size: 20px;
    font-weight: var(--medium);
    /*line-height: 1.4;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h3 {
    font-size: 16px;
    /*line-height: 1.5;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h4 {
    font-size: 14px;
    font-weight: var(--medium);
    /*line-height: 1.43;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h5 {
    font-size: 14px;
    font-weight: var(--medium);
    /*line-height: 1.14;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

h6 {
    font-size: 12px;
    /*line-height: 1.33;*/
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
}

.h7 {
    display: inline;
    font-size: 1.077em;
    font-weight: var(--medium);
    text-transform: uppercase;
    margin: 0;
}


/**************************************************************************************************************************************************************/


/**
Line dividers
 */

hr {
    background: var(--line-divider-color);
}

.material-icons {
    cursor: pointer;
    vertical-align: middle;
    font-size: 20px;
    height: 24px;
    width: 24px;
    margin-left: 24px;
    color: var(--secondary-color);
    opacity: var(--inactive-opacity);
    text-align: center;
}

.material-icons:hover {
    opacity: var(--hover-opacity);
}

.material-icons.active {
    opacity: var(--active-opacity);
}

.mdc-fab__icon {
    margin: 17px;
    color: #fff;
    opacity: 1;
}

.mdc-fab {
    background-color: var(--accent-color);
    box-shadow: 0 6px 6px 0 rgba(33, 150, 243, 0.24), 0 0 6px 0 rgba(33, 150, 243, 0.12);
}

.mini-fab-button .mdc-fab--mini {
    width: 24px;
    height: 24px;
    box-shadow: none;
    left: 76px;
    top: 18px;
}

.mini-fab-button .material-icons {
    font-size: 14px;
    margin-top: 5px;
}


/**************************************************************************************************************************************************************/


/**
NavigationBar header
 */


/* .nav-bar {
    background: var(--primary-color);
    top: 0;
    color: #fff;
    width: 100%;
    position: fixed;
    z-index: 500;
    line-height: 64px;
    height: 64px;
    vertical-align: middle;
}
.nav-bar h1 {
    display: inline-block;
    color: #fff;
}
.nav-bar i {
    color: #fff;
    opacity:var(--active-opacity);
    vertical-align: middle;
}
.nav-bar i:hover {
    color: #fff;
    opacity:var(--active-opacity);
}
.nav-right-item {
    display: flex;
    float: right;
    margin-right: 24px;
    line-height: 64px;
    height: 64px;
}
.nav-bar .mdc-layout-grid {
  padding: 0px !important;
}
.nav-bar-center-item {
  text-align: center;
}
.nav-bar-center-item h1{
    padding-left: 40%;
  text-transform: unset;
  font-weight: bold;
  font-size: 20px;
}
.nav-bar-center-item span{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  opacity:.9;
}
.logo-font {
    margin-left: 24px;
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
}
.logo-font strong {
    font-weight:bolder;
    text-transform: none;
} */


/**************************************************************************************************************************************************************/


/*
Logout Menu
 */

.logout-menu-avatar {
    height: 40px;
    right: 24px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 16px;
    font-weight: var(--medium);
    text-align: center;
    cursor: pointer;
}

.logout-menu-icon {
    /*position: absolute;
    top:0;*/
    text-align: right;
    float: right;
    margin-left: 14px;
}

.logout-menu-icon .material-icons {
    opacity: unset;
}

.info-menu-icon {
    /*position: absolute;
    top:0;*/
    text-align: right;
    margin-right: 24px;
    float: right;
}

.info-menu-icon .material-icons {
    opacity: unset;
}

.logout-menu-list {
    position: absolute;
    background: #ffffff;
    outline: none;
    list-style: none;
    border-radius: 2px;
    right: 24px;
    top: 0;
    padding: 0;
    max-width: 500px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
}

.logout-menu-list li {
    cursor: pointer;
    border-bottom: 1px solid var(--line-divider-color);
    padding: 12px 24px 6px 24px;
    font-size: 12px;
    line-height: 48px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
}

.logout-menu-list li:hover {
    background: var(--line-divider-color);
}

.logout-menu-userinfo {
    float: right;
    text-align: right;
    font-weight: 500;
}

.logout-menu-userinfo ul {
    padding-left: 26px;
}

.logout-menu-userinfo ul li {
    font-size: 10px;
    line-height: 16px;
    list-style-type: none;
}

.logout-menu-userinfo ul li:first-child {
    padding-top: 3px;
}

.logout-menu-userinfo ul li:nth-child(2) {
    color: #fbfdff;
}

.logout-username,
.logout-role {
    margin: 0;
    padding: 0;
    line-height: 24px;
}

.logout-username {
    font-size: 16px;
}

.logout-distributor {
    opacity: 0.5;
}

.logout-role {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
}

.logout {
    line-height: 32px!important;
}

.grep {
    float: right !important;
    text-align: right;
}

.grep::after {
    content: ' ';
    border-right: 1px solid #fbfdff;
    opacity: 0.26;
    padding: 10px 0;
}


/**************************************************************************************************************************************************************/


/*
Content
 */

.content {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 64px);
}

.right-drawer {
    direction: ltr;
}

.right-drawer>.mdc-temporary-drawer__drawer {
    max-width: 354px!important;
}

.mdc-temporary-drawer .mdc-temporary-drawer__drawer {
    margin-top: 64px;
}

.mdc-temporary-drawer .mdc-temporary-drawer__drawer .filter-wrapper {
    margin-top: 24px;
}

.users .dialog-upperpart .mdc-textfield,
.receipt-dialog mdc-textfield {
    max-width: unset !important;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
}

.cursor-not-allow {
    cursor: not-allowed;
}

.pointer-event-none {
    pointer-events: none;
}


/* .mdc-temporary-drawer__drawer {
    max-width: 256px!important;
} */


/* .left-drawer {
    padding-top: 24px;
    padding-bottom: 24px;
} */


/*
.mdc-permanent-drawer .mdc-list-item {
    font-size: 14px;
    color: rgba(0,0,0,0.38);
    font-weight: var(--medium);

}
.content .mini-drawer .mdc-list-item {
  justify-content: center;

}
.content .max-drawer .mdc-list-item {
    padding-left: 24px;

}
.mdc-permanent-drawer .mdc-list-item .active-link, .mdc-permanent-drawer .mdc-list-item .active-link:focus  {
    font-weight: var(--medium);
    background: var(--accent-color);
    color:white;

}
.mdc-permanent-drawer__content {
    margin-top: unset;
    margin-bottom: auto;

}
.navigation-menu {
    margin: auto 0;

}
.active-link {
    border-left : none;

}
.navigation-menu .mdc-list {
  padding-top: 0px;

}
.stock-detail-radio .mdc-list {
    display: flex;

}
.mdc-list-item {
    line-height: 48px;
}
.mdc-list-item[dir=rtl] .mdc-list-item__start-detail, [dir=rtl] .mdc-list-item .mdc-list-item__start-detail {
    margin: 0;

}
.mdc-list-item.hide-overflow-text {
    line-height: 48px;
}

.content .mini-drawer{
  width: 64px !important;

}
.content .max-drawer .drawer-item{
  width: 58px;

}

.drawer-item-second {
  color:rgba(0,0,0,0.54);

}
.bg-accent-color .drawer-item-second{
  color: #ffffff;

}
.mdc-permanent-drawer {
   min-height:100vh;
    border-right: none;
    display: flex;
    width: 256px;
    background: var(--left-drawer-color);

}
.drawer-menu-inside-menu-icon {
    position: absolute;
    right: 0;
    margin-right: 8px;
    opacity: 1;

}
.drawer-menu-inside-menu {
    height: inherit;
    width: inherit;
    line-height: normal;
    display: block;
    padding: 0!important;

}
.drawer-menu-inside-menu:focus {
    background: #fff!important;
}
.drawer-menu-inside-menu > .mdc-list-item {
    padding: 0 32px!important;
}
.border-right-accent {
    border-right: 2px solid var(--accent-color);
}

.bg-accent-color {
    background:var(--accent-color);
    color:white !important;
}
 */

.content-body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    flex: 1;
}

.content-body .app-body {
    height: calc(100vh - 74px);
    overflow: auto;
}

.pad-24-0-24-10 {
    padding: 24px;
    padding-top: 10px;
    padding-bottom: 0;
}

.pad-b-24 {
    padding-bottom: 24px;
}

.height-50 {
    height: 50px !important;
}

.content-body>.mdc-layout-grid .no-end-list-table {
    max-height: calc(100vh - 188px) !important;
    overflow: auto;
}

.content-body>.mdc-layout-grid .outstanding-report .no-end-list-table,
.content-body>.mdc-layout-grid .ageing-report .no-end-list-table {
    max-height: calc(100vh - 253px) !important;
    overflow: auto;
}


/* for ird reports height table */

.content-body>.mdc-layout-grid .th-table-height,
.content-body>.mdc-layout-grid .upper-th-table-height {
    max-height: calc(100vh - 272px) !important;
    overflow: auto;
}


/* for ird reports height table end */


/* for sales person outstanding report */

.content-body>.mdc-layout-grid .sales-outstanding .no-end-list-table {
    max-height: calc(100vh - 270px) !important;
    overflow: auto;
}


/* for sales person outstanding report end */

.content-body>.mdc-layout-grid .end-list-table {
    max-height: calc(100vh - 256px) !important;
    overflow: auto;
}

.content-body>.mdc-layout-grid {
    padding: 0 !important;
}

.fixed-table-wrapper table {
    position: relative;
}

.fixed-table-wrapper table th {
    position: sticky;
    top: 0;
    white-space: nowrap;
}

.right-arrow {
    content: '';
    background: url('./svg/right_arrow_white.svg') no-repeat 96% 50% var(--accent-color);
}

.menu-icon {
    margin-left: 20px !important;
    margin-right: 20px;
    font-size: 24px;
}


/**************************************************************************************************************************************************************/


/**
Margins and gaps
 */

.vertically-center-wrapper {
    line-height: 48px!important;
    height: 48px!important;
}

.vertically-center {
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
}

.vertically-center-using-position {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}

.side-gaps {
    margin: 0 16px;
}

.right-side-gap {
    margin-right: 16px;
}

.left-side-gap {
    margin-left: 16px;
}

.side-nav {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
}

.divider {
    margin: 24px 0;
    height: 1px;
    width: 100%;
    background: var(--line-divider-color);
}

.margin-l-5 {
    margin-left: 5px;
}


/**************************************************************************************************************************************************************/


/**
Buttons
 */

button {
    display: inline;
    font-size: 1.077em;
    font-weight: var(--medium);
    text-transform: uppercase;
    margin-right: 8px!important;
}

.modal-btn {
    padding-left: 8px!important;
    padding-right: 8px!important;
}


/*.cancel-btn {
    color: #000;
}*/


/**************************************************************************************************************************************************************/


/**
Popup
*/


/*For the border and the color of the arrow of the tooltip*/

.ToolTipPortal>div>div>span:first-of-type {
    border-bottom: 10px solid #FFFEF2!important;
}

.ToolTipPortal>div>div>span:last-of-type {
    border-bottom: 10px solid rgba(0, 0, 0, 0.15)!important;
}

.popup-wrapper {
    max-height: 400px;
    overflow: auto;
    outline: none;
}

.popup-header {
    margin-bottom: 4px;
    font-weight: var(--medium);
}

.popup-header i {
    float: right;
}

.popup-date {
    margin-bottom: 8px;
    opacity: var(--hover-opacity);
}


/**************************************************************************************************************************************************************/


/*
Badges
*/

.badge {
    cursor: pointer;
    float: none!important;
    font-size: 12px!important;
    font-weight: var(--medium);
    padding: 0 4px!important;
    border-radius: 2px;
    background: var(--gray-color);
    color: #ffffff!important;
    line-height: 15px;
    position: relative;
    text-align: right;
}

.badge:hover {
    background: var(--accent-color);
}

.active>.badge,
a.active>div>.badge {
    background: var(--accent-color);
}

.badge label {
    color: #ffffff;
}


/**************************************************************************************************************************************************************/


/**
Form
 */

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
    width: 100%;
    max-width: 341px;
}

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth) {
    margin-top: 0!important;
}

.mdc-textfield__input {
    width: 100%;
    background-color: transparent;
    font-size: 16px;
}

.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: rgba(0, 0, 0, 0.38);
    transform: translateY(100%) scaleY(1);
}

.mdc-textfield__label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    bottom: 12px;
    transform: translateY(-100%) scale(1);
}

.mdc-textfield__label--float-above {
    transform: translateY(-100%) scale(1);
}

.mdc-textfield--focused .mdc-textfield__label {
    color: var(--accent-color);
}

.mdc-textfield--focused.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: var(--accent-color);
    transform: translateY(100%) scale(1);
}

.mdc-textfield--upgraded.right-align input.mdc-textfield__input {
    text-align: right;
}


/* label color */

label {
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 0, 0, var(--hover-opacity));
}

.input-field label:not(.label-icon).active {
    left: 0;
}

.input-field.col label {
    left: 0!important;
}


/**
For radio button
 */

.mdc-radio>label {
    cursor: pointer!important;
    margin-left: 30px!important;
}


/*.mdc-checkbox__background {*/


/*height: 35%;*/


/*width: 35%;*/


/*top: 13px;*/


/*}*/


/*.mdc-checkbox__background > label {*/


/*cursor: pointer!important;*/


/*position: fixed!important;*/


/*left: 40px!important;*/


/*}*/


/*.mdc-checkbox:after {*/


/*background: none!important;*/


/*}*/

.mdc-checkbox__background {
    left: 8px;
    top: 9px;
    width: 46%;
    height: 46.5%;
}

.mdc-form-field {
    width: 140px;
    padding-right: 20px;
}

.mdc-form-field>label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding-top: 1px;
}

.mdc-checkbox {
    width: 14px;
    height: 14px;
    padding: 7px;
    vertical-align: middle;
}

.mdc-checkbox:after {
    height: 104%;
    top: 1px;
    left: -2px;
    background-color: rgba(63, 81, 181, .14);
}

.mdc-checkbox:before {
    left: -0.6px;
    top: 0.5px;
    height: 105.5%;
    width: 97%;
    background-color: rgba(63, 81, 181, .14);
}

.mdc-checkbox__background:before {
    top: -1.08px;
    left: -0.4px;
    width: 101.2%;
    height: 118%;
    background-color: rgba(63, 81, 181, .14) !important;
}

.mdc-checkbox__background:after {
    background-color: rgba(63, 81, 181, .14) !important;
}

.mdc-checkbox__native-control:checked~.mdc-checkbox__background {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

.mdc-checkbox__native-control {
    background-color: rgba(63, 81, 181, .14);
    /*top:-1.08px;
  left:-0.5px;
  width:101.8%;
  height:116.8%;*/
}

.mdc-checkbox__checkmark {
    top: -1px;
}

.input[type="checkbox"]:focus {
    background-color: rgba(63, 81, 181, .14);
}


/*
For dropdown
 */

select {
    padding: 8px;
    cursor: pointer;
    float: left;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    outline: none;
    appearance: none;
}

select option {
    display: none;
}

.Select-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
    height: 34px;
}

.Select-arrow {
    border-color: rgba(0, 0, 0, 0.54) transparent transparent;
}

.Select--single>.Select-control .Select-value {
    padding-left: 0px;
    line-height: 28px;
}

.custom-select-wrapper {
    position: relative;
    cursor: pointer;
    outline: none;
}

.custom-select {
    background: url('./svg/down_arrow.svg') no-repeat right;
    height: 48px;
    font-size: 15px;
    border-bottom: 1px solid var(--line-divider-color);
}

.custom-select-option {
    display: inline-block;
    line-height: 48px;
}

.custom-options-list-bottom {
    padding: 0;
    position: absolute;
    margin-top: 0;
    top: 0;
    width: 100%;
    z-index: 400;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-options-list-top {
    padding: 0;
    position: absolute;
    margin-top: 0;
    top: -95px;
    width: 100%;
    z-index: 400;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-options-list-top li,
.custom-options-list-bottom li {
    cursor: pointer;
    line-height: 48px;
    clear: both;
    list-style: none;
    padding: 0 16px 0 24px;
    color: #000;
    background: #fafafa;
}

.custom-options-list-bottom li:hover,
.custom-options-list-top li:hover {
    color: #000;
    background: #ededed;
}


/**************************************************************************************************************************************************************/


/*
Range
 */

.input-range__track {
    background: var(--gray-color);
}

.input-range__track--active {
    background: var(--accent-color);
}

.input-range__slider {
    background: var(--accent-color);
    border: 1px solid var(--accent-hover-color);
}

.input-range__slider:active {
    color: #fff;
    display: flex;
    position: absolute;
    top: -10px;
    left: -12px;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-left: -2px;
    -webkit-transform: rotate(-45deg) scale(0) translate(0, 0);
    -webkit-transition: -webkit-transform 100ms ease-out;
    transition: -webkit-transform 100ms ease-out;
    transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
    border-radius: 50% 50% 50% 0;
    z-index: 1;
    transform: rotate(-45deg) scale(1) translate(19px, -20px);
}


/**************************************************************************************************************************************************************/


/*
Progress Bars
 */

.progress {
    background-color: var(--accent-light-color);
}

.progress .indeterminate {
    background-color: var(--accent-color);
}

.spinner-blue,
.spinner-blue-only {
    border-color: var(--accent-color);
}


/**************************************************************************************************************************************************************/


/**
Pagination
 */


/* .pagination-wrapper {
    text-align: center;
    font-size: 13px;
    line-height: 48px;
    padding: 0 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    color:rgba(0,0,0,0.54);
}
.pagination-wrapper i {
    cursor: pointer;
    opacity: var(--hover-opacity);
    margin: 0!important;
}
.pagination-total-pages {
    min-width: 40px;
    height: 48px;
}
.pagination-wrapper i:hover, .pagination-page:hover {
    color: var(--secondary-color);
    transition: background-color 1s ease!important;
}
.pagination-page {
    cursor: pointer;
    font-size: 13px;
    margin-left: 8px;
    background: none;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
}
.pagination-page:last-of-type {
    margin-right: 8px;
}
.pagination-page-active {
    color: var(--secondary-color);
    opacity:var(--active-opacity);
}
.pagination-wrapper .custom-select {
    display: inline-block;
    float: left;
    background: url('./svg/arrow_drop_down.svg') no-repeat right;
    min-width: 42px;
    height: 48px;
    font-size:13px;
    border-bottom: none;
}

.pagination-wrapper .custom-select-option {
    display: inline-block;
    line-height:48px;
    float: left;
    font-weight: 500;
}
.pagination-display-label {
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
    font-size: 13px;
}
.pagination-label {
    cursor: pointer;
    color: var(--secondary-color);
    opacity: var(--inactive-opacity);
}
.pagination-label:hover {
    opacity: var(--hover-opacity);
}

.pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom, .pagination-wrapper .custom-select-wrapper > .custom-options-list-top {
    width: 70px !important;
}
.pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom li, .pagination-wrapper .custom-select-wrapper > .custom-options-list-top li{
    padding:0px 10px;
}
.pagination-wrapper .mdc-layout-grid {
  padding: 0px !important;
}

.page-leftside-wrapper-show{
  float: left;
  margin-right: 10px;
  font-size: 13px;
}
.nv-margin {
  margin-left: -60px;
} */


/**************************************************************************************************************************************************************/


/**
Datepicker
 */

.inline {
    display: inline;
}

.fixed-calendar-label {
    font-size: 14px;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
}

.fixed-calendar,
.fixed-calendar-active {
    margin: 0 8px 0 0;
}

.fixed-calendar-active {
    color: var(--accent-color)!important;
    opacity: initial;
}

.calendar-icon {
    border: none;
}

.calendar-arrow-icon {
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-color);
}

.date-range-picker-wrapper {
    position: relative;
    outline: none;
}

.date-picker-holder,
.date-picker-holder-active {
    cursor: pointer;
    padding-left: 24px;
}

.calendar-date {
    position: relative;
}

.calendar-status {
    width: 100px;
    position: absolute;
    top: -22px;
    font-size: 10px;
    color: var(--gray-color);
}

.date-picker-holder-active .calendar-status {
    color: var(--accent-color);
}

.date-picker-holder-active>.calendar-date,
.date-picker-holder-active>.calendar-arrow-icon {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--accent-color);
}

.date-picker-holder>.calendar-date {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-color);
}

.date-range-picker-view-toggle,
.date-range-picker-view-toggle-active {
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border: none;
    display: inline;
    border-radius: 0;
    outline: none;
    line-height: 3rem;
    width: 40%;
    margin: 0 0 20px 0;
    padding: 0 0 5px;
    box-sizing: content-box;
    transition: all 0.3s;
}

.date-range-picker-view-toggle-active {
    border-bottom: 1px solid var(--accent-color);
}

.date-range-picker-view-toggle {
    border-bottom: 1px solid var(--gray-color);
}

.rdr-Calendar {
    position: absolute;
    top: 56px;
    width: 295px!important;
    padding: 16px!important;
    margin-left: 24px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)!important;
    z-index: 50;
}

.rdr-DateRange {
    position: absolute;
    z-index: 300;
    top: 40px;
    left: 24px;
}

.is-inRange,
.is-selected {
    background: var(--accent-light-color)!important;
    color: #fff!important;
}

.rdr-Day:hover {
    background: var(--accent-color)!important;
    color: #fff!important;
    transition: background-color 1s ease!important;
}

.rdr-MonthAndYear-button.prev {
    cursor: pointer;
    background: url(svg/left_arrow.svg) no-repeat center!important;
}

.rdr-MonthAndYear-button.next {
    cursor: pointer;
    background: url(svg/right_arrow.svg) no-repeat center!important;
}

.rdr-MonthAndYear-button.prev>i,
.rdr-MonthAndYear-button.next>i {
    display: none!important;
}

.date-picker-wrapper {
    position: relative;
    float: right;
}

.date-picker-wrapper .rdr-Calendar {
    right: 0px;
    line-height: 20px;
}

.date-picker-wrapper .date-picker-holder {
    color: rgba(0, 0, 0, 0.54);
}

.date-picker-wrapper .date-picker-holder>.calendar-date {
    border-bottom: none;
}

.date-picker-wrapper .calendar-arrow-icon {
    border-bottom: none;
}

.date-picker-wrapper .date-range-picker-view-toggle-active,
.date-picker-wrapper .date-picker-holder-active>.calendar-date {
    border-bottom: none;
}

.dialog-date-picker-wrapper {
    position: relative;
    float: left;
    top: 2px;
    display: block;
    width: 100%;
}

.dialog-date-picker-wrapper .rdr-Calendar {
    top: -40px;
}

.dialog-date-picker-wrapper .date-picker-holder {
    padding-left: 0px;
}

.dialog-date-picker-wrapper #date-range-picker-id {
    padding-bottom: 8px;
}

.dialog-date-picker-wrapper .calendar-arrow-icon {
    padding-bottom: 8px;
    padding-left: 30%;
}


/*.date-picker-wrapper .react-datepicker {
  width:100%;
  border:none;
  box-shadow:  0 0 2px 0 rgba(0,0,0,0.12),  0 2px 2px 0 rgba(0,0,0,0.24);
}
.date-picker-wrapper .react-datepicker__month-container {
  width:100%
}
.react-datepicker__navigation--previous {
  top: 16px;
}
.react-datepicker__navigation--next {
  top: 16px;
}
.react-datepicker__header {
  padding-top: 16px;
  background: #ffffff;
  border-bottom: none;
}
.react-datepicker__current-month {
  font-size: 13px;
  color:var(--secondary-color);
  opacity:var(--active-opacity);
}
.react-datepicker__day-names{
    white-space: nowrap;
    margin-top: 3px;
    line-height: 11px;
    padding: 0px 16px;
    text-align: left;
    color:rgba(0,0,0,0.54);
  }*/


/* .date-rendered {
    color:rgba(0,0,0,0.54);
} */


/**************************************************************************************************************************************************************/


/**
Cards
 */

.card {
    padding: 24px;
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid var(--light-ripple-color);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)
}

.card i {
    margin: 0 5px;
}

.card-header-bar {
    background: none;
    line-height: 62px;
}

.card-body {
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}


/**************************************************************************************************************************************************************/


/*
Grid
 */


/*.mdc-layout-grid__cell, .mdc-layout-grid__inner {*/


/*margin-bottom: 24px!important;*/


/*}*/


/*.mdc-layout-grid__cell:last-of-type {*/


/*margin-bottom: 0!important;*/


/*}*/

.content-body>.mdc-layout-grid {
    padding-top: 10px;
}

.border-black {
    border: 1px solid black;
}


/**************************************************************************************************************************************************************/


/**
Table
 */

table {
    background: #fff;
    border-collapse: collapse;
    text-align: left;
    font-size: 13px;
    min-width: 100%;
    table-layout: fixed;
    /*box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);*/
}

thead {
    border-bottom: none;
    /*font-weight: var(--medium);*/
}

td,
th {
    padding: 0 16px;
    white-space: nowrap;
}

th {
    background: #f4f4f4;
    color: rgba(0, 0, 0, 0.54);
    line-height: 48px;
    font-size: 14px;
    font-weight: normal;
}

td {
    line-height: 47px;
    font-weight: var(--regular);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

tr {
    border-bottom: 1px solid #f7f7f7;
}

td:first-of-type,
th:first-of-type {
    padding-left: 24px;
}

td:last-of-type,
th:last-of-type {
    padding-right: 24px;
}

.table-wrapper {
    padding-bottom: 100px;
    border-bottom: 1px solid #efefef;
}

.fixed-table-wrapper {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.card-body .fixed-table-wrapper {
    box-shadow: unset;
    max-height: unset !important;
    overflow: auto;
}

.fixed-table-header {
    table-layout: fixed;
}

.table-header {
    position: fixed;
    width: 100%;
    background: #f7f7f7;
    color: var(--gray-color);
    font-weight: normal;
    line-height: 48px;
    padding: 0 24px;
}

.table-footer {
    width: 100%;
    padding: 0 24px;
    background: #fff;
}

table tfoot.right-content-footer tr td:first-of-type {
    width: 80%;
}


/**************************************************************************************************************************************************************/


/*
Filter
 */

.filter-head {
    padding: 16px 24px;
    border-bottom: 1px solid var(--gray-color-2);
}

.filter-body {
    padding: 16px 0;
}

.filter-title {
    display: block;
    cursor: pointer;
    border-bottom: 1px solid var(--line-divider-color);
    line-height: 48px;
    height: 48px;
    padding: 0 24px;
    font-size: 13px;
    font-weight: var(--medium);
}

.active-filter-title {
    background: url('./svg/down_arrow.svg') no-repeat 95%;
}

.passive-filter-title {
    background: url('./svg/up_arrow.svg') no-repeat 95%;
}

.filter-content {
    padding-left: 24px;
}


/**************************************************************************************************************************************************************/


/**m
Sidebar
*/

#sidenav-overlay {
    background: none!important;
}


/**************************************************************************************************************************************************************/


/**
Tabs
 */

.mdc-tab-bar {
    float: left;
}

.mdc-tab-header {
    vertical-align: middle;
}

.mdc-tab-body {
    clear: both;
}

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:after {
    background: var(--accent-color);
}

.mdc-tab .badge {
    margin-left: 8px;
}

.mdc-tab--active .badge {
    background: var(--accent-color);
    opacity: var(--active-opacity);
}


/**************************************************************************************************************************************************************/


/*
Modals
*/

.mdc-dialog__body .date-picker-holder {
    padding-left: 0px !important;
}

.mdc-dialog__surface {
    margin-top: 40px;
    max-width: 780px;
    min-width: 500px;
}

.mdc-dialog__header {
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.mdc-dialog__body {
    border-bottom: 1px solid var(--line-divider-color);
    padding: 0px;
    margin: 0;
}

.mdc-dialog__body>div:not(:first-of-type) {
    margin-top: 20px;
}

.mdc-dialog__body--scrollable {
    top: 20px;
    max-height: calc(100vh - 250px);
}

.dialog-header-close {
    display: unset !important;
}

.dialog-header-content span {
    margin-right: 5px;
    font-size: 20px;
    font-weight: 500;
}

.image-dialog-body {
    padding: 4px 4px 3px;
}


/**************************************************************************************************************************************************************/


/**
Accordion
 */

.Collapsible__trigger {
    display: block;
    cursor: pointer;
    border-bottom: 1px solid var(--line-divider-color);
    line-height: 48px;
    height: 48px;
    padding: 0 24px;
    font-size: 13px;
    font-weight: var(--medium);
    background: url('./svg/down_arrow.svg') no-repeat 95%;
}

.Collapsible__trigger.is-open {
    background: url('./svg/up_arrow.svg') no-repeat 95%;
}

.Collapsible__contentOuter {
    transition: max-height 0.2s ease-out;
    font-size: 13px;
    font-weight: var(--medium);
    padding: 0 16px;
}


/**************************************************************************************************************************************************************/

.no-margin {
    margin: 0!important;
}

.no-margin-left {
    margin-left: 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding {
    padding: 0!important;
}

.default-horizontal-margin-24 {
    margin: 0 24px;
}

.default-horizontal-margin-16 {
    margin: 0 16px;
}

.default-horizontal-margin-12 {
    margin: 0 12px;
}

.default-horizontal-margin-8 {
    margin: 0 8px;
}

.default-vertical-margin-24 {
    margin: 24px 0;
}

.default-vertical-margin-16 {
    margin: 16px 0;
}

.default-vertical-margin-12 {
    margin: 12px 0;
}

.default-vertical-margin-8 {
    margin: 8px 0;
}

.default-margin-24 {
    margin: 24px;
}

.default-left-margin-24 {
    margin-left: 24px;
}

.default-left-margin-9 {
    margin-left: 9px;
}

.default-margin-top-12 {
    margin-top: 12px;
}

.default-margin-top-2 {
    margin-top: 2px;
}

.default-margin-top-3 {
    margin-top: 3px;
}

.default-margin-top-24 {
    margin-top: 24px !important;
}

.default-margin-top-7 {
    margin-top: 7px;
}

.default-top-padding-12 {
    padding-top: 12.5px;
}

.default-horizontal-padding-24 {
    padding: 0 24px;
}

.default-horizontal-padding-16 {
    padding: 0 16px;
}

.default-horizontal-padding-8 {
    padding: 0 8px;
}

.default-vertical-padding-24 {
    padding: 24px 0;
}

.default-vertical-padding-16 {
    padding: 16px 0;
}

.default-vertical-padding-8 {
    padding: 8px 0;
}

.margin-left-24 {
    margin-left: 24px;
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-b-2 {
    margin-bottom: 2px !important;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-top-24 {
    padding-top: 24px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-l-2 {
    padding-left: 2px !important;
}

.padding-r-4 {
    padding-right: 4px !important;
}

.padding-r-8 {
    padding-right: 8px !important;
}

.padding-r-24 {
    padding-right: 24px !important;
}

.padding-r-8 {
    padding-right: 8px !important;
    ;
}

.padding-l-24 {
    padding-left: 24px !important;
}

.padding-l-0 {
    padding-left: 0px !important;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-b-8 {
    padding-bottom: 8px;
}

.padding-b-12 {
    padding-bottom: 12px !important;
}

.padding-top-18 {
    padding-top: 18px !important;
}

.null {
    height: 0!important;
    width: 0!important;
}

.cursor-default {
    cursor: default!important;
}

.center-margin {
    margin: 0 auto;
}

.relative {
    position: relative;
}

.center-align {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.full-opacity-icon .material-icons {
    opacity: 1;
}

.hide-overflow-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.success-color {
    color: var(--success);
}

.failure-color {
    color: var(--error);
}

.fs-14 {
    font-size: 14px;
}

.border-bottom {
    border-bottom: 1px solid var(--border-bottom);
}

.last-tr-without-border tbody>tr:last-child {
    border-bottom: none;
}

.hide-overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.image-thumbnail {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.image-thumbnail .material-icons {
    margin-left: 0px;
}

.image-yes .material-icons {
    opacity: var(--hover-opacity);
}

.image-no .material-icons {
    opacity: var(--disabled-opacity);
    cursor: default;
}


/*Image Gallery*/

.image-gallery-play-button {
    display: none;
}


/*.image-gallery-fullscreen-button {
  top:8px !important;
  bottom:unset;
  right:-10px;
  position: fixed;
  font-size: 24px;
}*/

.image-gallery-fullscreen-button {
    top: 11.4px !important;
    bottom: unset;
    right: 35px;
    position: fixed;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
    color: #dddddd;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    top: 55%;
    font-size: 48px;
    position: fixed;
}

.image-gallery-bullets {
    bottom: 20px;
    position: fixed;
}

.image-gallery-play-button::before {
    font-size: 30px;
}

.image-gallery-fullscreen-button::before {
    font-size: 18px;
    cursor: pointer;
    color: var(--secondary-color);
    opacity: var(--inactive-opacity);
    text-shadow: none;
}

.image-gallery-fullscreen-button:hover::before {
    transform: none;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
}

.bill-summary-input {
    width: 40px;
}

.margin-auto {
    margin: auto;
}

.display-inline-table {
    display: inline-table;
    text-align: left;
}

.no-pointer-events {
    pointer-events: none;
}


/**********/


/*header-menu */

.search-icon-wrapper {
    background: #f6f6f6;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    -webkit-transition: 5s ease-in-out;
    -moz-transition: 5s ease-in-out;
    -o-transition: 5s ease-in-out;
    transition: 5s ease-in-out;
    transition-delay: 1s;
}

.search-icon-wrapper .search-icon {
    position: absolute;
    top: 7px;
    left: -19.5px;
    font-size: 20px !important;
}

.cross-icon {
    margin-left: 0px;
    position: absolute;
    top: 20px;
    right: -12px;
}


/************************************************************************************************************************************************/


/** input**/

.input-field {
    /*position: relative;*/
    /*margin-top: 1rem; */
}

input[type=text],
input[type=number] {
    /*background-color: transparent;
border: none;
border-bottom: 1px solid #9e9e9e;
border-radius: 0;
outline: none;
height: 3rem;
width: 100%;
font-size: 1rem;
margin: 0 0 20px 0;
padding: 0;
-webkit-box-shadow: none;
box-shadow: none;
-webkit-box-sizing: content-box;
box-sizing: content-box;
-webkit-transition: all 0.3s;
transition: all 0.3s;*/
}


/** Short hand css**/

.right-align {
    text-align: right;
}

.right {
    float: right;
}

.left-align {
    text-align: left;
}

.center-align {
    text-align: center;
}

.active-opacity-text {
    color: rgba(0, 0, 0, 0.54);
}

.check {
    /* border-bottom: 1px solid red; */
}


/** Sales Invoice **/

.header-menu {
    clear: both;
}


/* .header-menu-left {
  float: left;
}
.header-menu-right {
  float: right;
} */

.date-wrapper {
    width: 60%;
    float: right;
}

.top-search-input-wrapper {
    float: right;
    position: relative;
}

.input-inactive {
    width: 0% !important;
}

.input-active {
    width: 200px !important;
    margin-left: 4px;
}


/*.top-search-input-wrapper .mdc-textfield__input {
  transition:display 40s;
  transition-delay: 40s;
}*/


/** New Invoice **/

.three-input-wrapper {
    padding: 12px 0px 0px;
}

.total-section-wrapper {
    font-size: 12px;
    padding-bottom: 15px;
}

.total-section-wrapper .mdc-layout-grid:not(:first-child) {
    padding: 10px 24px 0px;
    /*height:54px;*/
}

.total-section-wrapper .mdc-layout-grid:first-child {
    padding: 24px 24px 0px;
    /*height: 54px;*/
}

.total-section-wrapper .Select-menu-outer ul li {
    text-align: left;
}

.total-section-wrapper .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
    width: 74px;
    height: 34px;
    margin-bottom: 0px;
}

.total-section-wrapper .mdc-textfield__input {
    text-align: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 0 6px;
}

.total-section-wrapper .Select-control {
    background: transparent;
}

.Select-control {
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.Select-arrow {
    border-color: rgba(0, 0, 0, 0.54) transparent transparent;
}

.total-section-wrapper .Select-control {
    height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.total-section-wrapper .Select--single>.Select-control .Select-value {
    text-align: left;
    line-height: 32px;
}

.total-section-wrapper .Select-input {
    height: 28px;
    margin-right: 9px;
    vertical-align: baseline;
}

.total-section-wrapper .Select-arrow-zone {
    width: 20px;
    padding-right: 0px;
}

.total-section-wrapper .Select-arrow {
    border-width: 3.5px 3.5px 2px;
}

.hr {
    margin: 20px 0px 3px;
    border-bottom: 1px solid #efefef;
}

.font-bold {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.88);
}

.font-medium {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
}

.newinvoice-btn-wrapper .mdc-layout-grid {
    padding-right: 0px;
}

.stock-journal-create+.newinvoice-btn-wrapper .mdc-layout-grid {
    padding-right: 24px;
}

.modal-btn {
    font-size: var(--medium);
    min-width: 64px;
    border-radius: 2px;
}

.newinvoice-btn-wrapper .save-btn {
    margin-right: 0px !important;
}

.save-btn {
    color: #ffffff !important;
    background: var(--accent-color) !important;
    margin-left: 8px !important;
}

.cancel-btn {
    margin-right: 8px;
    background: rgba(153, 153, 153, 0.2) !important;
    color: rgba(0, 0, 0, 0.38) !important;
}


/** dialog box **/

.dialog-upperpart {
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.dialog-detail {
    font-size: 16px;
    display: block;
    margin-top: 4px;
}

.accent-color {
    color: var(--accent-color);
}

.dialog-stock-detail-color {
    color: #ff2d55;
}

.dialog-lowerpart {
    padding: 0px 24px;
    margin-top: 4px !important;
}

.dialog-ok {
    color: var(--accent-color) !important;
    background: transparent;
}

.dialog-cancel {
    color: rgba(0, 0, 0, 0.38) !important;
    background: transparent;
}

.mdc-button:before {
    background: transparent !important;
}

.quantity-type-select {
    position: absolute;
    top: 19px;
    right: 0px;
    padding-left: 8px;
    border-left: 1px solid #eeeeee;
    border-bottom: none !important;
    width: 60px;
    font-size: 10px;
    height: 25px;
}

.quantity-type-select .Select-arrow-zone {
    padding-left: 5px;
    padding-top: 7px;
    padding-right: 7.5px;
}

.quantity-type-select .Select-control {
    border-bottom: none !important;
    height: 25px;
}


/*.quantity-type-select .Select--single > .Select-control .Single-value {
  line-height: 29px;
}*/

.quantity-type-select .Select-control .Select-value {
    line-height: 28px;
}

.quantity-type-select .Select-input {
    height: 12px;
}

.quantity-type-select .Select-input>input {
    padding: 0px;
}

.has-value.Select--single>.Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
    color: rgba(0, 0, 0, 0.87);
}

.relative-container {
    position: relative;
}

.mdc-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 3px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

.save-btn:after {
    background: rgba(217, 237, 253, 0.6);
}

.dialog-ok:after {
    background: rgba(37, 152, 243, 0.32);
}

.cancel-btn:after {
    background: rgba(136, 136, 136, 0.25);
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}

.mdc-button:focus:not(:active)::after {
    animation: ripple 1s ease-out;
}

.input-select .Select-control {
    height: 30px;
    background-color: transparent;
}

.input-select .Select-input {
    height: 28px;
}

.header-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
}

.header-title-content {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
}

.header-bar {
    border-bottom: 1px solid #efefef;
}

.header-middle-title {}

.header-middle-content {
    border-bottom: 1px solid #efefef;
    padding: 30px 0px;
    text-align: center;
}

.btn-disabled {
    pointer-events: none;
    opacity: var(--disabled-opacity);
}

.inp-disabled {
    pointer-events: none;
    opacity: var(--hover-opacity);
}

.input-select .Select-control {
    height: 29px;
    background-color: transparent;
}

.input-select .Select-input {
    height: 28px;
}


/* .header-bar {
  margin-top: 14px;
} */

.header-title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 6px;
    font-weight: 400;
}

.header-title-content {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
}

.header-bar {
    border-bottom: 1px solid #efefef;
}

.header-middle-title {
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-middle-title h2 {
    font-size: 24px;
}

.header-right-content {
    font-size: 14px;
    display: block;
}

.header-right-content .date {
    display: block;
}

.print-invoice .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline):after {
    background-color: transparent !important;
}

.clearfix {
    clear: both;
    display: block;
    content: '';
}

.clearfix::after {
    clear: both;
    display: block;
    content: '';
}

.header-left {
    float: left;
}

.header-right {
    float: right;
    color: rgba(0, 0, 0, 0.54);
}


/*sales vat register table*/

.table-border-bottom {
    border-bottom: 1px solid #e6e3e3;
}

th.table-cell {
    width: 70%;
    border-right: 1px solid #e6e3e3;
}

th.table-2-cell {
    width: 30%;
}

.assignSkuDialog .mdc-dialog__body--scrollable {
    min-height: 300px;
}

.list-header {
    margin-top: 2px;
}

.list-with-checkbox .mdc-form-field {
    display: block;
    width: 100%;
}

.list-with-checkbox .mdc-checkbox {
    padding: 8px;
    width: 16px;
    height: 15px;
}

.list-with-checkbox .mdc-form-field>label {
    font-size: 14px;
    padding-top: 5px;
}

.list-with-checkbox .mdc-checkbox::before,
.list-with-checkbox .mdc-checkbox::after {
    left: -1px;
    top: 0px;
    height: 100%;
    width: 100%;
}

.list-with-checkbox .mdc-checkbox__background::before {
    left: -1px;
    top: 0px;
    height: 100%;
    width: 100%;
}

.mdc-textfield {
    margin-bottom: 6px;
}

.Select {
    margin-bottom: 6px;
}

.mdc-textfield--invalid:not(.mdc-textfield--focused) .mdc-textfield__label {
    color: rgba(0, 0, 0, var(--hover-opacity));
}

.mdc-textfield--invalid:not(.mdc-textfield--focused):after {
    background-color: #d50000 !important;
}

.invalid.mdc-textfield:after {
    background-color: #d50000 !important;
}

.error-message {
    display: block;
    margin-top: 0px;
    color: #d50000;
    font-size: 10px;
    line-height: 13px;
}

.billing-required .mdc-textfield__input:required+.mdc-textfield__label:after {
    display: none;
}


/* Radio-list*/

.radio-list-wrapper {
    padding: 6px 24px;
    border-bottom: 1px solid #efefef;
}

.radio-list-wrapper .mdc-list {
    padding: 0px;
}

.radio-list-wrapper .mdc-form-field {
    padding-right: 0;
    min-width: 100px;
    width: auto;
}

.radio-list-wrapper .mdc-radio {
    width: 36px;
    height: 36px;
}

.radio-list-wrapper .mdc-radio:before,
.radio-list-wrapper .mdc-radio:after {
    top: 1px;
}

.radio-list-wrapper .mdc-list-item,
.radio-list-wrapper .mdc-list {
    display: flex;
    flex-wrap: wrap;
}

.card-body>.radio-list-wrapper .mdc-list-item {
    overflow: unset;
    height: unset;
}

.card-body>.radio-list-wrapper .mdc-list-item label {
    white-space: normal;
}

.radio-disabled {
    pointer-events: none;
    opacity: var(--disabled-opacity);
}

div.radio-disabled+label {
    pointer-events: none !important;
    opacity: var(--disabled-opacity)!important;
}

div.active-radio+label {
    color: rgba(0, 0, 0, var(--active-opacity));
}

.block-overlay {
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
}

.block-overlay .three-input-wrapper .select-css .zindex-2__control,
.block-overlay .total-section-wrapper .select-css .zindex-2__control {
    pointer-events: none;
    background-color: #fafafa;
}


/*checkbox*/

.common-checkbox .mdc-checkbox::before,
.common-checkbox .mdc-checkbox::after,
.common-checkbox .mdc-checkbox__background::before,
.common-checkbox .mdc-checkbox__background::after {
    width: 100%;
    height: 100%;
    left: 0px;
}

.common-checkbox .mdc-checkbox__background::before,
.common-checkbox .mdc-checkbox__background::after {
    top: 0px;
}


/*Message Wrapper */

.message-wrapper {
    background: rgba(239, 239, 239, 0.8);
    padding: 20px;
}

.message-title {
    font-size: 16px;
}

.sub-message {
    color: rgba(0, 0, 0, 0.54);
    line-height: 20px;
    font-size: 12px;
    font-weight: normal;
}

.ledger-type-radio-group {
    padding: 0px !important;
}

.ledger-type-radio-group .mdc-list-item {
    display: inline-block;
}

.ledger-type-radio-group .mdc-form-field {
    width: auto;
}

.ledger-type-radio-group .mdc-radio {
    width: 36px;
    height: 36px;
}

.message-snackbar .mdc-snackbar--active {
    bottom: 4%;
    left: auto;
    transform: translate(-10%, 0);
    transition: webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 0ms;
    /*transition: transform 0.5s;*/
    line-height: 48px;
    padding: 0 20px 0 24px;
    background: #434343;
    min-width: 140px;
    opacity: 1;
}

.mdc-snackbar {
    left: auto;
    z-index: 9999;
    opacity: 1;
    transform: translate(-10%, 100%);
    transition: webkit-transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    transition: transform .25s cubic-bezier(.4, 0, 1, 1) 1ms;
    /*transition: transform 1s ease-in-out 0.1s;*/
    min-width: 140px;
}

.message-snackbar .success-message .mdc-snackbar__action-button {
    display: none !important;
}

.message-snackbar .mdc-snackbar__action-button {
    background: none;
    border: none;
    margin-left: 48px;
    margin-right: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-accent-focused-color);
}

.mdc-snackbar .mdc-snackbar__text {
    font-size: 14px;
    font-weight: 400;
    color: #F8F8F8;
    height: unset;
    min-height: 40px;
}

.print-active {
    display: block;
}

.display-block {
    display: block;
}

.no-print {
    display: none;
}

.display-inline-block {
    display: inline-block;
    width: 100%;
}


/* @page {
    margin: 0 !important;
    size: A4 portrait !important;
} */

.span-wrapper {
    float: left;
    width: 25%;
}

table.print-active th,
table.print-active td {
    line-height: unset;
    background: unset;
    border-bottom: none;
    padding-left: 0px;
}

table.header>thead th:first-child {
    width: 54%;
    padding: 0px;
}

table.footer>tfoot td {
    width: 40%;
    color: #000000 !important;
    opacity: 0.8 !important;
    padding-right: 0 !important;
}


/* .download-menu {
    position: relative;
} */


/* .header-menu-right .mdc-menu-anchor {
    position: absolute;
} */

.header-menu-right .mdc-simple-menu--open-from-top-right {
    right: -42px !important;
    background: #fafafa;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12);
}

.header-menu-right .mdc-simple-menu .mdc-list-item {
    font-size: 13px;
}

.header-menu-right .mdc-simple-menu .mdc-list-item:active:before {
    background: rgba(238, 238, 238, 1) !important;
}

.header-menu-right .mdc-simple-menu .mdc-list {
    padding: 0px;
}

table.log-table {
    table-layout: fixed;
    width: 100%;
}

table.log-table td,
table.log-table th {
    padding: 0 16px;
    white-space: normal;
}

table.log-table th {
    line-height: unset;
    font-weight: normal;
    width: 124px;
    padding: 14px 12px;
}

table.log-table td {
    vertical-align: top;
    line-height: unset;
    font-weight: var(--regular);
    padding: 10px 12px;
}

table.log-table td:nth-of-type(5),
table.log-table th:nth-of-type(5) {
    width: 324px !important;
}

table.log-table th:first-of-type,
table.log-table td:first-of-type {
    padding-left: 24px;
    width: 50px !important;
}

.cash-checkbox {
    position: relative;
    top: 9px;
}

.cash-checkbox .mdc-checkbox:before {
    left: 0px;
    top: 0.5px;
    height: 100.5%;
    width: 100%;
    background-color: rgba(63, 81, 181, .14);
}

.cash-checkbox .mdc-checkbox:after {
    left: 0px;
    top: 0.5px;
    height: 100.5%;
    width: 100%;
    background-color: rgba(63, 81, 181, .14);
}

.single-right-menu {
    right: -35px;
    left: unset !important;
}

.overflow-scrollable {
    overflow: auto !important;
}

.customer-select-inp {
    position: relative;
    top: -7px;
}

.select-custom-label {
    position: relative;
    top: -3px
}

.textbox {
    max-width: unset!important;
}

.table-input-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.table-input-right .mdc-textfield--upgraded {
    height: 40px !important;
}

.hr-scrollable-table {
    overflow-x: auto;
}


/* added */

.stock-ledger.ledger-report .fixed-table-wrapper table tbody td,
.svr-table tbody tr,
.pvr-table tbody tr,
.prvr-table tbody tr,
.vat-report-table tbody tr {
    cursor: unset;
}

.sku table tbody tr td,
.stock-ledger .fixed-table-wrapper table tbody td,
.journal-voucher table tbody td,
.ledger-report table tbody td {
    cursor: pointer;
}

.customer-ledger .dialog-upperpart .row {
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.customer-ledger .dialog-upperpart .row:last-child {
    border-bottom: 0;
}

.customer-ledger table thead th {
    z-index: 2;
}

:is(.customer-ledger,
.users,
.srn-create) .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 50px;
}

.table-scroll {
    overflow: auto;
}

.header-menu-right .mdc-simple-menu .mdc-list-item {
    font-size: 13px;
}

.outstanding-report .header-menu-right,
.card-body .header-menu-right,
.ageing-report .header-menu-right {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin-top: 5px;
    position: relative;
}

.outstanding-report .header-menu-right .mdc-menu-anchor,
.card-body .header-menu-right .mdc-menu-anchor,
.ageing-report .header-menu-right .mdc-menu-anchor {
    position: unset;
}

.outstanding-report .header-menu-right .transaction-download,
.card-body .header-menu-right .transaction-download,
.ageing-report .header-menu-right .transaction-download {
    left: unset !important;
    right: 0;
    top: 29px !important;
}

.outstanding-report .transaction-download ul,
.card-body .header-menu-right .mdc-simple-menu .mdc-list {
    transform: unset !important;
}

.ageing-report .header-menu-right .header-menu-left .mdc-textfield {
    height: unset;
}

.header-menu-right .header-menu-left {
    display: flex;
    align-items: center;
}

.header-menu-right .header-menu-left>span {
    display: flex;
    align-items: center;
}

.ageing-report .header-menu-right .header-menu-left {
    position: relative;
    top: -6px;
}

.ageing-report .header-menu-right .header-menu-left .input-active {
    width: unset !important;
    flex: 1;
    position: relative;
    margin-left: 10px;
}

.ageing-report .header-menu-right .header-menu-left .input-active i {
    position: absolute;
    top: 0;
    right: -3px;
}


/* create-grn styling */

.create-grn .form-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 48px;
}

.create-grn .form-wrap .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 36px;
}

.create-grn .form-wrap .Select {
    margin: 0;
}

.create-grn .form-wrap span {
    flex: 1;
}

.create-grn .form-wrap .mini-fab-button button {
    left: 9px;
    top: 4px;
}

.create-grn .form-wrap .mini-fab-button {
    flex: unset;
}

.create-grn .form-wrap .disabled .material-icons {
    cursor: not-allowed;
}

.create-grn .form-wrap .disabled {
    background-color: #B3CDFF;
    opacity: 0.7;
    cursor: not-allowed;
}

.create-grn .select-grn .mdc-layout-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.create-grn .select-grn .select-grn-title {
    margin-bottom: 16px;
}

.create-grn .width-100 .mdc-layout-grid__inner {
    width: 100%;
}

.create-grn .date-batch-dialog .mdc-layout-grid__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 65.66%;
}

.grn-create .row_one+.row .grid-padding {
    padding: 0;
}

.grn-create .row_one .date-range-picker-wrapper .default-vertical-padding-24 {
    padding-bottom: 0;
}

.grn-create .row_one .select-grn-title {
    margin-bottom: 32px;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .row_three .label {
    top: -5px;
    position: relative;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .row_three .mdc-textfield {
    height: 49px !important;
}

.table-scroll {
    overflow: auto;
}

.create-grn .date-batch-dialog .date-picker-holder-active {
    padding-left: 0;
}

.mdc-dialog__body .dialog-upperpart .sku-modal-date .rdr-Calendar {
    top: -1339%;
    right: -17%;
}

.create-grn .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 36px;
}

.mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 56px;
}

.radio-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-list-wrapper .float-left {
    flex: 1;
}

.radio-list-wrapper .float-right.cash-checkbox {
    position: unset;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .three .label {
    display: block;
    margin-top: 7px;
    position: relative;
    top: -2.5px;
}

.sales-invoice-create .mdc-dialog .three .Select-control .Select-arrow-zone {
    position: relative;
    right: -10px;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .dialog-detail {
    margin-top: 6px;
}

.sales-invoice-create .mdc-dialog .mdc-dialog__body .custom-stock label {
    color: rgba(0, 0, 0, var(--hover-opacity))
}

.abi-create .sub-total-wrap .mdc-textfield {
    height: 26px !important;
}

.sample-csv {
    position: absolute;
    bottom: 12px;
    left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: var(--accent-color);
    font-weight: 500;
}

.sample-csv i.material-icons {
    color: var(--accent-color);
    opacity: 1;
    margin-top: 2px;
    margin-left: 0;
    margin-right: 5px;
}


/* opening blnc */

.opening-blnc table th:last-child {
    text-align: right;
}


/* srn vat reports */

.srn-vat .display-block .header-bar .center-align .default-margin-top-12 {
    margin-top: 0;
}

.grn-create .mdc-dialog__surface .mdc-dialog__body .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 27px;
}


/* grn srn stock adjustment create dialog styling */

.srn-create .dialog-upperpart .row .grid-padding,
.sales-invoice-create .dialog-upperpart,
.grn-create .dialog-upperpart,
.grn-create .dialog-upperpart .row,
.stock-journal-dialog .dialog-upperpart,
.customer-ledger .dialog-upperpart,
.srn-create .dialog-upperpart {
    padding: 0;
}

.srn-create .dialog-upperpart .fab-div {
    padding: 0px 0px 14px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    bottom: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label {
    position: relative;
    top: -6px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label span {
    color: green;
    font-size: 10px;
}

.srn-create .sales-invoice-number .auto-complete-pop-up+.success-label .material-icons {
    margin-left: 0;
    font-size: 21px;
    color: green;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .filter-item+.filter-dropdown-card {
    min-width: 100%;
    top: 126%;
    box-shadow: unset;
    border: 0;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .filter-item+.filter-dropdown-card .autocomplete-list {
    padding: 6px;
    box-shadow: rgb(0 0 0 / 10%) 1px 2px 10px 1px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(231, 231, 237);
    ;
}

.srn-create .sales-invoice-number .auto-complete-pop-up .mdc-textfield {
    height: unset;
}

.srn-create .dialog-upperpart .label {
    position: relative;
    top: -2.5px;
}

.srn-create .sales-invoice-number .mdc-textfield__input:required+.mdc-textfield__label:after {
    content: unset;
}

.customer-ledger .dialog-upperpart .upload {
    padding: 24px;
}

:is(.customer-ledger,
.grn-create) .row_one .mdc-checkbox+label {
    position: relative;
    bottom: -2px;
}

.customer-ledger .row_one .select-css .zindex-2__menu-list {
    max-height: 200px;
    overflow: auto;
}

.sales-invoice-create .cash-checkbox {
    top: 0px;
    left: -8px;
    display: flex;
    align-items: center;
}

.sales-invoice-create .net-amount.dialog-detail,
.sales-invoice-create .rate.dialog-detail {
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    padding-bottom: 8px;
}

.sales-invoice-create .dialog-upperpart .row,
.grn-create .dialog-upperpart .row,
.stock-journal-dialog .dialog-upperpart .row,
.srn-create .dialog-upperpart .row {
    border-bottom: 1px solid var(--line-divider-color);
    padding: 24px;
}

.grn-create .dialog-upperpart .row:last-child,
.sales-invoice-create .dialog-upperpart .row:last-child,
.grn-create .dialog-upperpart .row_two>label,
.stock-journal-dialog .dialog-upperpart .row:last-child,
.srn-create .dialog-upperpart .row:last-child {
    border-bottom: 0;
}

.stock-journal-dialog .dialog-upperpart .row_two>label,
.srn-create .dialog-upperpart .row_two>label {
    display: block;
    padding-bottom: 12px;
}

.sales-invoice-create .dialog-upperpart,
.srn-create .dialog-upperpart,
.stock-journal-dialog .dialog-upperpart,
.customer-ledger .dialog-upperpart.overflow-active,
.grn-create .dialog-upperpart {
    max-height: 65vh;
    overflow: auto;
}

.grn-create .dialog-upperpart .mini-fab-button .mdc-fab--mini,
.srn-create .dialog-upperpart .mini-fab-button .mdc-fab--mini {
    left: 0;
}

.srn-create .dialog-upperpart .discount input,
.srn-create .dialog-upperpart .discount input {
    color: var(--accent-color);
}

.abi-create .rate .mdc-textfield {
    height: 49px !important;
}

.prn-create .dialog-upperpart .select-css .zindex-2__control {
    min-height: 35px;
}

.prn-create .sub-total-wrap .mdc-textfield {
    height: 26px !important;
}

.grn-create .mdc-dialog__body .dialog-upperpart .sku-modal-date .rdr-Calendar {
    top: 56px;
    right: unset;
    left: -20px;
}

.grn-create .dialog-upperpart .date-picker-holder-active {
    padding-left: 0;
}

.grn-create .header-right .date-range-picker-wrapper>.default-vertical-padding-24 {
    padding: 0;
}

.grn-create .header-right .date-picker-wrapper .calendar-status {
    display: none;
}

.grn-create .header-right .date-picker-wrapper .rdr-Calendar {
    top: 52px;
}

.grn-create .header-right>.date-picker-wrapper .date-picker-holder-active,
.grn-create .header-right>.date-picker-wrapper .date-picker-holder {
    text-align: right;
}

.account-group .fixed-table-wrapper table th {
    z-index: 1;
}


/* navbar icons styling */

.max-drawer .header .header-wrap::before {
    content: url('./svg/down_arrow.svg');
}

.fym-dialog-body .fym-error span::before {
    content: url('./svg/err.svg');
    position: relative;
    top: 3px;
}

.mini-drawer .header .header-wrap p img::before,
.max-drawer .header.active .header-wrap::before {
    content: url('./svg/up_arrow.svg');
}


/* tab UI */

.react-tabs {
    position: relative;
}

.react-tabs .react-tabs__tab-list {
    padding: 0;
    list-style: none;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab {
    display: inline-block;
    min-width: 80px;
    padding: 0 16px;
    text-align: center;
    color: #6b6c7e;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab--selected {
    border-bottom: 4px solid #2196F3;
    color: #2196F3;
    outline: 0;
    line-height: 30px;
}


/* users stylign */

.users .dialog-upperpart .password {
    position: relative;
}

.users .fixed-table-wrapper table th {
    z-index: 1;
}

.users .dialog-upperpart .password .pw_display {
    position: absolute;
    top: -20px;
    margin: 0;
    color: rgb(40, 125, 60);
    ;
    font-size: 11px;
}

.users .dialog-upperpart .password .pw_criteria {
    margin: 0;
    color: #d50000;
    font-size: 10px;
    line-height: 13px;
}


/* agents styling */

.agents .fixed-table-wrapper table th {
    z-index: 2;
}


/* grn srn prn salesInvoice Report styling */

.no-data .pagination-wrapper {
    visibility: hidden;
}


/* vat summary filter taxable amount styling */

.input-by-wrap {
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding: 24px;
}

.link-voucher:hover {
    color: #2196f3;
    text-decoration: underline;
    cursor: pointer;
}