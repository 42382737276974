@media print {
   .linear-progress-wrapper ,
    .print_display_none{
        display:none ;
    }
    table tfoot td.pad-l-10{
        padding-left:10px !important;
    }
    .display_print_only{
        display:table-cell !important;
    }

    .portrait,
    .portrait-type,
    .landscape,
    .visibility-shown{
        visibility: visible !important;
    }
    .custom-three-input-wrapper label,
    .active-opacity-text{
        color:#000000 !important;
    }
    p{
        margin:0;
    }
    html, body {
        height: 100%;
    }
    .custom-three-input-wrapper label,
    .active-opacity-text{
        color:#000000 !important;
    }
    @page {
        margin: 0 !important;
        /* size: A4 portrait !important; */
        /* size: A4 landscape !important; */
        counter-increment: page;
        counter-reset: page 1;
    }
    .message-snackbar,
    ::-webkit-scrollbar ,
    .content .mini-drawer ,
    .mdc-permanent-drawer ,
    .nav-bar,nav,.newinvoice-btn-wrapper,.no-print{
        display: none !important;
    }
    .content{
        margin-top:0 !important;
    }
    .content-body .app-body,
    .content{
        height:unset !important;
        overflow:unset !important;
    }
    .pad-b-24,
    .pad-24-0-24-10{
        padding:0 !important;
    }
    .header-title{
        font-size:16px !important;
        line-height:18px !important;
        font-weight:500 !important;
    }
    .header-right-content {
        font-size: 13px !important;
    }
    .header-bar .mdc-layout-grid__inner,
   .first-header-bar .mdc-layout-grid__inner{
        display:inline-block!important;
        width:100%;
    }
    .header-bar .mdc-layout-grid__cell,
    .first-header-bar .mdc-layout-grid__cell{
        width:33.33%;
        display:inline-block;
        vertical-align: text-top;
    }
    :is(.header-bar,.first-header-bar) .mdc-layout-grid__cell:nth-child(2) h2{
        text-align:center;
        font-size:18px;
    }
    :is(.header-bar,.first-header-bar) .mdc-layout-grid__cell:nth-child(2) h5{
        text-align:center;
        font-size:14px;
        font-weight:400;
    }
    .header-middle-content{
        text-align:left !important;
    }
    .three-input-wrapper .mdc-layout-grid .mdc-textfield__input{
        font-size:13px;
    }
    .three-input-wrapper  .mdc-layout-grid__cell--span-5,
   .three-input-wrapper  .mdc-layout-grid__cell--span-3{
        grid-column-end: span 2 !important;
    }
    .total-section-wrapper .mdc-layout-grid__cell--span-3{
        grid-column-end: span 7 !important;
    }
    .mdc-layout-grid{
        padding:0 !important;
    }
    .content-body > .mdc-layout-grid {
        padding-top: 0px !important;
        background-color:#FFFFFF !important;
    }
    .print-invoice .mdc-layout-grid .mdc-layout-grid__inner{
        display:flex !important;
    }
    .print-invoice .mdc-layout-grid .mdc-layout-grid__inner>div{
        flex: 1;
    }
    .card-body .fixed-table-wrapper .sku{
        width:350px;
    }
    table th, table td{
        padding:0 10px !important;
        font-size:13px !important;
        white-space: nowrap !important;
    }
    table td:last-child, table th:last-child{
        padding-right:0 !important;
    }
    table td:nth-child(2), table th:nth-child(2){
        padding-left:0 !important;
    }
    table td:first-of-type, table th:first-of-type {
        padding-left:0 !important;
        width: 18px !important;
        white-space: nowrap !important;
    }
    table {
        table-layout: fixed !important;
        min-width: 100% !important;
    }
    table tbody tr {
        width: 100%;
        border-bottom: none !important;
    }
    table tbody td{
        color:#000000 !important;
        opacity: unset !important;
        line-height: 24px !important;

    }
    table thead th{
        color: #000000 !important;
        opacity: unset !important;
        font-weight: 500 !important;
        line-height: 28px !important;
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        background: rgb(244, 244, 244) !important;
    }
    table tbody tr td:nth-child(2) {
        width: auto;
    }

    table tbody tr td.id {
        width: unset !important;
        width:40px !important;
    }
    table tbody tr td.title {
        width: auto !important;
    }

    .total-section-wrapper .mdc-layout-grid {
        padding:0px !important;
    }
    .first-header-bar {
        border-bottom: none !important;
    }
    .header-bar {
        margin-top: 0 !important;
        border-bottom: none !important;
    }
    .card-body {
        border: none;
        margin-top: 0px;
        box-shadow: none!important;
    }
    .print-active {
        display: block;
    }
    table.print-active tfoot {
        width: 100% !important;
        display:inline-table !important;
    }
    table.print-active tfoot tr ,table.print-active tfoot tr{
        width: 100%;
        border-bottom: none;
    }
    table.print-active th, table.print-active td {
        line-height: unset;
        background: unset;
        border-bottom: none;
        padding-left: 0px;
    }

    table.header > thead th:first-child {
        width: 54%;
        padding: 0px;
    }
    table.footer > tfoot td{
        width: 40%;
        color: #000000 !important;
        padding-right: 0 !important;
    }
    .fixed-table-height{
        box-shadow: none !important;
        /* min-height: 865px !important; */
        font-size: 12px !important;
    }
    .hr {
        margin: 5px 0 !important;
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
    }
    .extra-content {
        content: '';
        display: block;
        margin-right: 100px;
    }
    table.header-table{
        top: 0;
        display: none;
    }
    table.header-table tr td{
        content:'';
        display: none;
    }
    table.footer-table{
        position: fixed;
        bottom: 0;
        page-break-after: always;
    }
    table.footer-table tr td{
        text-align: center;
        color: #000;
        line-height: 60px;
        font-size: 12px;
        content:'----' ;
    }
    table {
        page-break-after:auto;
    }
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group !important; }
    tfoot { display:table-footer-group }
    .footer-block {
        page-break-after: always;
    }
    .footer-block{
        position:absolute;
        bottom:-3px;
        left:0;
        width:100%;
        height:20px;
        color: #000000 !important;
        opacity: unset !important;
    }
    .fixed-table-wrapper.total-footer + .footer-block{
   background-color:#FFFFFF;
    }
    .print-body {
        position: relative;
        height:1200px;
        padding:20px;
        overflow:auto;
    }
    .fixed-table-wrapper {
        box-shadow: none !important;
        width: auto !important;
        margin-top:16px;
        min-height:800px;
    }

    /* for sales invoice only start  */

    .portrait .footer.total-section-wrapper,
    .footer-totalList{
        position:absolute;
        width:95%;
        bottom:10px;
        padding:0 20px !important;
        left:0;
    }


    .agent_info .agent_value{
        margin-left:6px;
    }
    /* for sales invoice only end  */
    .more-settings {
        display: none !important;
    }
    #more-settings {
        display: none !important;
    }
    .tfoot-td {
        line-height: 23px !important;
    }
    .line-height-none {
        line-height: 0 !important;
    }
    .signature-line {
        width: 18%;
        height: 10px;
        margin: 26px 0px 0px;
        border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .header-middle-content {
        border-bottom: none !important;
    }

    table.voucher-table td,th {
        padding: 0 16px;
        white-space: normal;
    }
    table.voucher-table th {
        line-height: unset;
        width: 100px !important;
        white-space: normal;
        padding: 2px 10px 8px;
        font-weight: 500 !important;
        font-size: 14px;
        color: #000000;
        line-height: 14px !important;
    }
    table.voucher-table td {
        vertical-align: top;
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 12px;
        padding: 8px 12px 7px;
        max-height: 62px !important;
        min-height: 30px;
        overflow: hidden;
        color: #000000 !important;
        line-height: 14px !important;
    }
    table.voucher-table td:not(:nth-of-type(2)):not(:first-child):not(:last-child),table.voucher-table th:not(:nth-of-type(2)):not(:first-child):not(:last-child){
        width: 8% !important;
    }
    table.voucher-table td:first-child,table.voucher-table th:first-child{
        width: 20px !important;
    }
    table.voucher-table td:nth-child(2),table.voucher-table th:nth-child(2){
        width: 20% !important;
    }
    table.voucher-table td:last-child,table.voucher-table th:last-child{
        width: 20% !important;
    }
    table.pdf-table{
        font-size: 12px;
    }
    table.pdf-table td, th {
        padding: 0 16px;
        white-space: normal;
    }
    table.pdf-table th {
        line-height: unset;
        font-size: 12px;
        font-weight: normal;
        width: 100px !important;
        white-space: normal;
        padding: 10px 12px;
    }
    table.pdf-table td {
        vertical-align: top;
        line-height: unset;
        white-space: normal;
        font-weight: var(--regular);
        font-size: 11px;
        padding: 9px 12px;
    }
    table.pdf-table tr {
        border-bottom:1px solid rgba(0,0,0,0.54);
    }
    td:first-of-type, th:first-of-type {
        padding-left: 24px;
    }
    td:last-of-type, th:last-of-type {
        padding-right: 24px;
    }
    .pdf-table-wrapper {
        height: 970px !important;
    }
    .zoom-reduce-medium {
        zoom:0.8;
        -moz-transform: scale(0.8);
        -moz-transform-origin: 0 0;
    }
    .zoom-reduce {
        zoom:0.7;
        -moz-transform: scale(0.7);
        -moz-transform-origin: 0 0;
    }
    .zoom-less-reduce {
        zoom: 0.90;
        -moz-transform: scale(0.90);
        -moz-transform-origin: 0 0;
    }
    .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline) {
        max-width: unset !important;
        height: 25px !important;
    }
    .header-middle-content {
        padding: 0 !important;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    table.total-section-wrapper .total-content-data{
        padding:0 !important;
    }
    table.total-section-wrapper  .tfoot-td .total_value,
    table.total-section-wrapper .total-content-data.item_value{
        width:10% !important;
        white-space: nowrap !important;
        padding-left:10px !important;
    }
    .page-number {
        font-size: 11px;
        color: rgba(0,0,0,0.6);
    }
    .report-table-wrapper {
       height: 860px !important;
    }
    .report-table-footer {
        height:850px !important;
    }
    .pdf-body {
        min-height:990px !important;
    }
    .lg-pdf-body {
        min-height: 1450px !important;
        padding-bottom: 40px !important;
    }
    .left-footer .mdc-layout-grid {
        padding-left: 0px !important;
    }
    .signature-content{
        position: relative;
        padding-left: 12px;
        float: left;
    }

    .signature-content:after {
        content:"__________________________";
        position: absolute;
        top:25px;
        left:-2px;

    }
    .jv-max-width {
        width: 100% !important;
    }
    .signature-content {
        width: 30% !important;
    }
    tr.hr-line {
        border-top: 1px solid rgba(0,0,0,0.54);
    }
    .payment-total-footer {
        min-height: 700px;
    }
    .header-border-bottom {
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        padding: 0px 0px 8px !important;
    }
    table.footer > tfoot.jv-footer td{
        width: 26%;
    }
    .total-section-wrapper .tfoot-td {
        padding:24px !important;
    }
    .total-section-wrapper .tfoot-td .mdc-layout-grid:first-child{
        padding:0 !important;;
    }
    .total-section-wrapper .tfoot-td {
        padding:5px 0 !important;
    }
    .total-section-wrapper .tfoot-td.total{
        padding-bottom:0 !important;
        line-height:10px !important;
    }
    .tfoot-td.total-word .left{
        padding-right:6px;
    }
    .tfoot-td.total-word .right{
        font-style: italic;
    }
    .total-section-wrapper tr td .authorized{
        padding-top:4px;
    }
    .signature-line{
        margin:0 !important;
    }
    .total-section-wrapper tr td .remark,
    .total-section-wrapper .tfoot-td .mdc-layout-grid:first-child{
        padding:0 !important;;
    }
    .total-section-wrapper .tfoot-td .span-wrapper{
        width:unset;
        float:unset;
    }
    .total-section-wrapper .tfoot-td  .td-wrap{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }
    .input-print{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        border-bottom: 1px solid rgba(0,0,0,0.54);
    }
    .input-print .text-area.print{
        width:100%;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         overflow:hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
        margin:0;
        font-size:13px;
    }
    .continue-nxt{
        position: absolute;
        bottom:0px;
    }

    /* portrait-type styling */
    .portrait-type .print-body{
        height:1200px !important;
    }
    .portrait-type .total-section-wrapper .mdc-layout-grid__cell--span-3{
        grid-column-end: span 3 !important;
    }
    .portrait-type .total-section-wrapper .grid__cell--span-8{
        grid-column-end: span 4 !important;
    }
    .portrait-type table th{
        word-spacing: -1px;
    }
    .portrait-type table tr{
        height:32px !important;
    }
    .portrait-type table th,
    .portrait-type table td{
    padding:5px !important;
    line-height:unset !important;
    }
    .portrait-type table .table-tfoot{
        border-top:1px solid rgba(0,0,0,0.54);
    }
    .portrait-type  .jv-footer td{
        padding:0 !important;
    }
    .portrait-type .payment-total-footer{
        min-height:0 !important;
    }
    .portrait-type .sign{
        display:flex;
        justify-content: space-between;
        margin-top:20px;
    }
    .portrait-type .display-none{
        display:none;
    }
    .portrait-type .signature-content{
        display: inline-block;
        width:unset !important;
        margin:auto;
        padding:0;
        text-align: center;
    }
    .portrait-type .signature-content span{
        display: inline-block;
        margin-left:35px;
    }
    .portrait-type .table-tfoot td{
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
        border-top: 1px solid rgba(0,0,0,0.54) !important;
        font-weight: 600 !important;
    }
    .portrait-type .page-tfoot-end{
        border-bottom: 1px solid rgba(0,0,0,0.54) !important;
    }
    .portrait-type .page-tfoot{
        border-top: 1px solid rgba(0,0,0,0.54) !important;
    }
    .portrait-type .jv.card-body{
        height:unset !important;
    }
    .portrait-type .jv.card-body .fixed-table-wrapper{
        height:950px;
    }
    .portrait-type .mdc-layout-grid {
        padding:0 !important;
    }
    .portrait-type .jv-footer tr{
        height:25px !important;
    }

.total_quantity_td{
    position: relative;
}
.batch-disable{
    position: absolute;
    left: 39%;
    top: 17px;
}
.batch-enable{
    position: absolute;
    left:45%;
    top: 6px;
}
.invoice_value{
    text-transform: capitalize;
}
}
@media print and (max-width: 1122.51px){
    .print_display_none_A4{
        display:none;
    }
}
 @media print and (max-width: 8.27in){
 }
 @media print and (max-width:5.83in) {
    /* styling for A5 */
    body{
        zoom:70.4912%;
    }
}
