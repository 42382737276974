.pannel-section{
    margin: 48px 70px;
     h4 {
    margin-bottom: 16px;
    line-height:16px;
}

.pannel-header{
    margin-bottom: 48px;
    h1{
      text-transform: capitalize;
    }
}
.disabled_col{
    cursor: not-allowed;
    .zindex-2__control--is-disabled{
        background-color:#FFFFFF;
    }
}

.button{
    display: flex;
    justify-content: flex-end;
    .mdc-button{
    background-color: #2196F3;
    color: #FFFFFF;
    margin-right: 0px !important;
}
}

.pannel-card{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 48px;
     .checkbox{
    display: flex;
    flex-wrap:wrap;
 .mdc-checkbox{
    width: 18px;
    height: 18px;
    padding: 11px;
    
}
span{
       margin-right: 48px;
    }
}
&.bill_print{
    .checkbox{
        display: flex;
        flex-direction: column;
        .mdc-checkbox{
            position: relative;
            left: -7px;
        }
    }
}
}
}